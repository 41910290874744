// This overrides the 'react-bootstrap-typeahead' readonly input that hints the
// first suggestion as you type

.rbt-input-hint {
	// sass-lint:disable-block no-important
	& > input {
		border: 0 !important;
		border-left: 3px solid transparent !important;
		padding: 0 1rem !important;
		height: 2.75rem;
		line-height: 1.5 !important;
		color: rgba($white, 0.25) !important;
		font-family: inherit;
		font-size: 1rem !important;
	}
}
