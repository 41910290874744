// sass-lint:disable no-important
.sortable-item {
	pointer-events: auto !important;
}

.sortable-container {
	& > .sortable-item {
		.sortable-handle {
			path {
				transition: fill 100ms ease-out;
				fill: rgba($white, 0.33);
			}

			&:hover {
				cursor: grab;

				path {
					fill: rgba($white, 0.67);
				}
			}
		}
	}
}

// While dragging is active, a clone of .sortable-item is appended to body.
// That's why we're using SCSS here instead of Styled Components; we need the
// body selector to target it.
body {
	& > .sortable-item {
		border-left-color: $blue-base;
		box-shadow: 0 0.1875rem 0.25rem rgba($black, 0.3);

		.sortable-handle {
			cursor: grabbing;

			path {
				fill: rgba($white, 0.67);
			}
		}
	}
}
