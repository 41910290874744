// sass-lint:disable-all

px-datepicker a, [datepicker] a, .datepicker a {
  color: inherit;
  text-decoration: none;
}

px-datepicker a:hover, [datepicker] a:hover, .datepicker a:hover {
  text-decoration: none;
}

px-datepicker select, px-datepicker select:focus, px-datepicker select:hover,
.datepicker select, .datepicker select:focus, .datepicker select:hover,
[datepicker] select, [datepicker] select:focus, [datepicker] select:hover {
	width: 100%;
	overflow: hidden;
	background: none;
	color: $white;
	background-color: $dark-gray;
	border-radius: $global-radius;
	border: 0;
	margin-top: 0.25rem;
}

px-datepicker,
.datepicker,
[datepicker],
._720kb-datepicker-calendar-header,
._720kb-datepicker-calendar-body,
._720kb-datepicker-calendar-days-header,
._720kb-datepicker-calendar-years-pagination-pages {
  font-family: $base-font-family;
  font-size: 0.8rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  float: left;
  clear: right;
  position: relative;
}

._720kb-datepicker-calendar {
  background: $dark-gray;
  color: $text-light;
  position: absolute;
  z-index: 50;
  min-width: 13.75rem;
  margin: 0 auto;
  width: 101%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  visibility: hidden;
  overflow: hidden;
  margin-left: -0.5%;
  padding: 0 0 2% 0;
  border-radius: $global-radius;
}

._720kb-datepicker-calendar._720kb-datepicker-open,
._720kb-datepicker-calendar._720kb-datepicker-forced-to-open {
  visibility: visible;
}

._720kb-datepicker-calendar-header {
  text-align: center;
  font-size: 0.9rem;
  line-height: 2.5rem;

	._720kb-datepicker-calendar-month-button {
		&:hover {
			background: transparent;
		}
	}
}

._720kb-datepicker-calendar-header:nth-child(odd) {
  background: transparent;
}

._720kb-datepicker-calendar-header:nth-child(even) {
  background: transparent;
}

._720kb-datepicker-calendar-header-left,
._720kb-datepicker-calendar-header-middle,
._720kb-datepicker-calendar-header-right {
  width: 15%;
  float: left;
}

._720kb-datepicker-calendar-header-middle {
  width: 70%;

	a {
		pointer-events: none;

		span {
			i {
				display: none;
			}
		}
	}
}

._720kb-datepicker-calendar-header-closed-pagination::after {
  content: ' \25BE';
}

._720kb-datepicker-calendar-header-opened-pagination::after {
  content: ' \25BE';
  margin-left: 0.25rem;
  position: relative;
  bottom: -0.25rem;
  display: inline-block;
  transform: rotate(180deg);
}

._720kb-datepicker-calendar-body {
  width: 96%;
  margin: 0 2% 2%;
  text-align: center;
}

._720kb-datepicker-calendar-day {
  cursor: pointer;
  font-size: 0.8rem;
  width: 14.18%;
  margin: 5px 0;
  padding: 1.5% 0;
  float: left;
  border-radius: 1px;
	color: rgba($white, 0.75);
}

._720kb-datepicker-calendar-day:hover,
._720kb-datepicker-calendar-day:focus,
._720kb-datepicker-calendar-day._720kb-datepicker-active {
  font-weight: 700;
	color: $white;
	border-radius: $global-radius;
}

._720kb-datepicker-calendar-day:hover {
	background: transparent;
}

._720kb-datepicker-calendar-day._720kb-datepicker-active {
	background: $primary-color;
}

._720kb-datepicker-calendar-header a,
._720kb-datepicker-calendar-header a:hover {
  text-decoration: none;
  padding: 3% 9% 4% 9%;
  font-size: 0.8rem;
  color: $text-light;
  font-weight: bold;
  border-radius: $global-radius;
}

._720kb-datepicker-calendar-header a:hover {
  color: $white;
  background: rgba($white, 0.1);
}

._720kb-datepicker-calendar-month {
  color: $white;
}

._720kb-datepicker-calendar-month span {
  font-size: 0.9rem;
  color: $white;
}

._720kb-datepicker-calendar-month a span i {
  font-style: normal;
  font-size: 1rem;
}

._720kb-datepicker-calendar-month a,
._720kb-datepicker-calendar-month a:hover {
  padding: 0;
  margin-left: 1%;
	background: transparent;
}

._720kb-datepicker-calendar-years-pagination {
  padding: 2% 0 0 0;
  float: left;
  clear: right;
  width: 100%;
}

._720kb-datepicker-calendar-years-pagination a,
._720kb-datepicker-calendar-years-pagination a:hover {
  font-size: 0.75rem;
  padding: 0 0.5rem;
  font-weight: normal;
  margin: 0.25rem 1% 0 1%;
  line-height: 1.25rem;
  display: inline-block;
}

._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active {
  color: $white;
  font-weight: 700;
  background: $primary-color;
}

._720kb-datepicker-calendar-years-pagination-pages a,
._720kb-datepicker-calendar-years-pagination-pages a:hover {
  padding: 0.375rem 0.625rem;
	background: transparent;
}

._720kb-datepicker-calendar-days-header {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2% 0 2%;
  background: transparent;
  border-bottom: 1px solid rgba(0,0,0,0.02);
}

._720kb-datepicker-calendar-days-header div {
  width: 14.18%;
  font-weight: 700;
  font-size: 0.7rem;
  padding: 0.625rem 0;
  float: left;
  text-align: center;
  color: hsla(0,0,100,0.25);
	text-transform: uppercase;
}

._720kb-datepicker-calendar-days
._720kb-datepicker-default-button {
  font-size: 1.125rem;
  position: relative;
  bottom: -0.5px;
}

._720kb-datepicker-default-button {
  padding: 0 0.25rem;
}

._720kb-datepicker-calendar-header-middle._720kb-datepicker-mobile-item {
  width: 95%;
  float: none;
  margin: 0 auto;
}

._720kb-datepicker-item-hidden {
  visibility: hidden;
}

._720kb-datepicker-calendar-day._720kb-datepicker-disabled,
._720kb-datepicker-calendar-day._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled:hover {
  color: rgba($text-light, 0.3);
  background: rgba(25,2,0,0.02);
  cursor: default;
}

._720kb-datepicker-today {
	background: rgba($white, 0.1);
	color: $white;
	border-radius: $global-radius;
}

px-datepicker.range {
	._720kb-datepicker-today {
		background: $primary-color;
		font-weight: 700;
		border-radius: 2000px;
	}

	._720kb-datepicker-range {
		background: $primary-color;
		color: $white;
		border-radius: 0;
		font-weight: 700;

		&:hover {
			background: $primary-color;
			border-radius: 0;
		}

		&._720kb-datepicker-today {
			border-top-left-radius: 2000px;
			border-bottom-left-radius: 2000px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&._720kb-datepicker-active {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-top-right-radius: 2000px;
			border-bottom-right-radius: 2000px;

			&._720kb-datepicker-today {
				border-top-left-radius: 2000px;
				border-bottom-left-radius: 2000px;
			}
		}

		&._720kb-datepicker-disabled,
		&._720kb-datepicker-disabled:hover {
		  color: rgba($text-light, 0.3);
		  background: rgba(25,2,0,0.02);
		  cursor: default;
		}
	}
}
