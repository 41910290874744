// sass-lint:disable no-css-comments, no-empty-rulesets, no-vendor-prefixes
$px-auth-cubic-bezier: cubic-bezier(0.68, -0.55, 0.265, 1.55);

.px-auth-overlay {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	z-index: 100;
	background: $body-background;
	width: 100vw;
	height: 100vh;
}

.px-auth-modal {
	margin: 1.5rem;
	outline: 0;
	border-radius: $global-radius;
	box-shadow: $px-card-box-shadow;
	background: $px-card-bg;
	padding: 1.5rem;
	width: 100%;
	max-width: 28rem;
}

.px-auth-modal-header {
	text-align: center;
}

.px-auth-modal-header-title {
	margin-bottom: 1rem;
	font-weight: 700;
}

.px-auth-modal-body {
	margin-bottom: 1.5rem;
	text-align: center;

	[type='text'],
	[type='email'],
	[type='password'] {
		margin-bottom: 1rem;
	}
}

.px-auth-modal-error {
	padding-bottom: 0.75rem;
	color: $color-error;
}

.px-auth-modal-success {
	padding-bottom: 0.75rem;
	color: $color-success;
}

.px-auth-modal-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 2.5rem;

	.px-auth-modal-footer-forgot,
	.px-auth-modal-footer-btn {
		margin: 0;
	}
}

// Transitions
.ReactModal__Content { // sass-lint:disable-line class-name-format
	// transform: translateY(-6rem);
	transform: scale(0.7) translateY(4rem);
	opacity: 0;
}

.ReactModal__Content--after-open { // sass-lint:disable-line class-name-format
	// transform: translateY(0);
	transform: scale(1) translateY(0);
	transition: transform 0.25s $px-auth-cubic-bezier, opacity 0.25s $px-auth-cubic-bezier;
	opacity: 1;
}

.ReactModal__Content--before-close { // sass-lint:disable-line class-name-format
	// transform: translateY(5rem);
	transform: scale(0.7) translateY(4rem);
	opacity: 0;
}

// Disable html, body scrolling
.px-html-auth-modal-open,
.px-body-auth-modal-open {
	overflow: hidden;
}

/* stylelint-disable */
// Hack to detect autofill in Chrome
@keyframes onAutoFillStart {
}
@keyframes onAutoFillCancel {
}

#loginEmail,
#loginPassword {
	&:-webkit-autofill {
		animation-name: onAutoFillStart;
	}

	&:not(:-webkit-autofill) {
		animation-name: onAutoFillCancel;
	}
}
/* stylelint-enable */
