// sass-lint:disable no-url-domains, no-url-protocols

@charset 'utf-8';

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700');
@import url('https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap-reboot.css');

@import 'theme';
@import 'fonts';
@import 'legacy';
@import 'colors';
@import 'mixins';
@import 'common';
@import 'type';

@import 'video.js/dist/video-js.css';
@import 'vendor/react-widgets';

@import '../layouts/main-layout';

// TODO: Migrate everything in components/ to ../modules/components/ or ../layouts/
@import 'components/badges';
@import 'components/buttons';
@import 'components/checkboxes';
@import 'components/dashboard';
@import 'components/datepicker';
@import 'components/forms';
@import 'components/labels';
@import 'components/menus';
@import 'components/modals';
@import 'components/scaling';
@import 'components/scrollbars';
@import 'components/switch';
@import 'components/toasts';
@import 'components/tooltip';
@import 'components/tooltips';
@import 'components/typeahead';

@import '../modules/components/components';

@import '../app/index';
@import '../_pages/project-crud/index';
@import '../app/(private)/work-requests/new/index';
