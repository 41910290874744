.label {
	display: inline-block;
	border-radius: $global-radius;
	background: $darkest-gray;
	cursor: default;
	padding: 0.33333rem 0.75rem;
	text-transform: uppercase;
	line-height: 1;
	letter-spacing: 1px;
	white-space: nowrap;
	color: $medium-dark-gray;
	font-size: 0.8rem;
	font-weight: 700;

	&.primary {
		background: $color-primary-dark;
		color: $color-primary-light;
	}

	&.alert {
		background: $color-error-dark;
		color: $color-error;
	}
}
