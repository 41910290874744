$px-modal-cubic-bezier: cubic-bezier(0.68, -0.55, 0.265, 1.55);

.px-modal-overlay,
.px-modal-overlay-clear,
.px-modal-overlay-clear50 {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	z-index: 99;
	background: $body-background;
	width: 100vw;
	height: 100vh;
}

.px-modal-overlay-clear {
	background: change-color($body-background, $alpha: 0.5);
}

.px-modal-overlay-clear50 {
	background: $body-background + AA;
}

// Transitions
.ReactModal__Content { // sass-lint:disable-line class-name-format
	// transform: translateY(-6rem);
	transform: scale(0.7) translateY(4rem);
	opacity: 0;
}

.ReactModal__Content--after-open { // sass-lint:disable-line class-name-format
	// transform: translateY(0);
	transform: scale(1) translateY(0);
	transition: transform 0.25s $px-modal-cubic-bezier, opacity 0.25s $px-modal-cubic-bezier;
	opacity: 1;
}

.ReactModal__Content--before-close { // sass-lint:disable-line class-name-format
	// transform: translateY(5rem);
	transform: scale(0.7) translateY(4rem);
	opacity: 0;
}

// Disable html, body scrolling
.px-modal-open {
	overflow: hidden;
}
