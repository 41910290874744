// Video.js Skin Editor: https://codepen.io/heff/pen/EarCt

$videojs-foreground-color: $white;
$videojs-background-color: $px-card-bg;
$videojs-big-play-width: 1.125em;
$videojs-big-play-height: 1.125em;
$videojs-slider-bg-color: lighten($videojs-background-color, 33%);

.px-video-preview {
	position: relative;
	padding-bottom: 2rem;

	.px-video-watermark {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		pointer-events: none;
	}

	.video-js {
		outline: 0;
		color: $videojs-foreground-color;
		font-family: $base-font-family;
		font-size: 0.625rem;
		font-weight: 700;

		.vjs-big-play-button {
			top: 50%;
			left: 50%;
			transition: opacity 0.15s;
			opacity: 0.8;
			margin-top: -($videojs-big-play-height / 2);
			margin-left: -($videojs-big-play-width / 2);
			border: 0;
			border-radius: 0.25em;
			background-color: $midnight-lighten-10;
			width: $videojs-big-play-width;
			height: $videojs-big-play-height;
			line-height: $videojs-big-play-height;
			font-size: 6em;
		}

		&:hover {
			.vjs-big-play-button {
				opacity: 1;
				background-color: $midnight-lighten-10;
			}
		}

		&:not(.vjs-fullscreen) {
			.vjs-control-bar {
				// sass-lint:disable-block no-important
				display: none !important;
				transform: translateY(3em);
				opacity: 1 !important;
			}
		}

		.vjs-time-tooltip {
			border-radius: $global-radius;
			background-color: rgba($black, 0.8);
			padding: 6px 8px;
			color: $white;
			font-family: $base-font-family;
			font-size: 1em;
		}

		button {
			outline: 0;
		}
	}

	&.disabled {
		pointer-events: none;
	}

	&:not(.notes-visible) {
		.video-js {
			&:not(.vjs-fullscreen) {
				.vjs-control-bar {
					// sass-lint:disable-block no-important
					display: flex !important;
				}
			}
		}
	}
}

.px-videojs-notes-btn {
	cursor: pointer;

	&:hover {
		filter: drop-shadow(0 0 0.125em rgba($white, 0.75));
	}

	&::before {
		display: block;
		background-color: $white;
		width: 100%;
		height: 100%;
		content: '';
		mask: url('/3x/img/svg/icon_notes.svg') no-repeat center;
		mask-size: 0.875rem;
	}
}

.px-watermark {
	display: flex;
	opacity: 0.068;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-shadow: $black 1px 1px 0;
	line-height: 1.5;
	color: $white;
	font-size: 3.5vmin;
	font-weight: 500;
	pointer-events: none;

	&-full-screen-diagonal {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		transform: scale(2) rotate(-45deg);
		transform-origin: center center;
		font-size: 1vmin;
		font-weight: 400;
	}

	&-single-center {
		align-items: center;
		justify-content: center;
	}

	&-single-top-middle {
		justify-content: center;
	}

	&-single-bottom-middle {
		align-items: flex-end;
		justify-content: center;
	}
}
