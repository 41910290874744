// sass-lint:disable no-important
// @todo switch all tooltips to use data-tooltip attrs
[data-tooltip] {
	position: relative;

	&::before,
	&::after {
		transition: opacity 0.15s;
		opacity: 0;
	}

	&::before {
		position: absolute;
		top: -0.325rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: 39;
		border-width: 0.25rem 0.325rem 0;
		border-style: solid;
		border-color: $blue-base transparent transparent;
		content: '';
	}

	&::after {
		position: absolute;
		top: -0.325rem;
		left: 50%;
		transform: translateX(-50%) translateY(-100%);
		z-index: 40;
		border-radius: $global-radius;
		background: $blue-base;
		padding: 0.25rem;
		min-width: 6rem;
		text-align: center;
		line-height: 1.4;
		color: $white;
		font-size: 0.8rem;
		font-weight: 700;
		content: attr(data-tooltip);
		pointer-events: none;
	}

	&:hover {
		&::before,
		&::after {
			opacity: 1;
		}
	}

	&:not(*[data-tooltip-position]) {
		&::before,
		&::after {
			transition: transform 0.1s;
		}

		&::before {
			transform: translateX(-50%) translateY(100%);
		}

		&::after {
			transform: translateX(-50%) translateY(-85%) scale(0.95);
			transform-origin: bottom;
		}

		&:hover {
			&::before {
				transform: translateX(-50%) translateY(0);
			}

			&::after {
				transform: translateX(-50%) translateY(-100%) scale(1);
			}
		}
	}
}

[data-tooltip-position='top'] {
	&::before {
		left: 50%;
	}

	&::after {
		left: 50%;
	}
}

[data-tooltip-position='right'] {
	&::before {
		top: 50%;
		left: 100%;
		transform: translateY(-50%) rotate(90deg);
		transition: opacity 0.2s;
		margin-left: 0.05rem;
	}

	&::after {
		top: 50%;
		left: 100%;
		transform: translateX(0%) translateY(-50%);
		transition: opacity 0.2s;
		margin-left: 0.5rem;
	}
}

[data-tooltip-position='bottom'] {
	&::before {
		top: 100%;
		transform: translateX(-50%) translateY(-100%) rotate(-180deg);
		transition: opacity 0.2s;
		margin-top: 0.5rem;
	}

	&::after {
		top: 100%;
		transform: translateX(-50%) translateY(0%);
		transition: opacity 0.2s;
		margin-top: 0.5rem;
	}
}

[data-tooltip-position='left'] {
	&::before {
		top: 50%;
		left: 0%;
		transform: translateY(-50%) rotate(-90deg);
		transition: opacity 0.2s;
		margin-left: -0.75rem;
	}

	&::after {
		top: 50%;
		left: 0%;
		transform: translateX(-100%) translateY(-50%);
		transition: opacity 0.2s;
		margin-left: -0.5rem;
	}
}

[data-tooltip-size='small'] {
	&::after {
		min-width: 4rem;
	}
}

[data-tooltip-size='large'] {
	&::after {
		min-width: 9rem;
	}
}

[data-tooltip-size='x-large'] {
	&::after {
		min-width: 12rem;
	}
}

[data-tooltip-color='blue'] {
	&::before {
		border-color: $blue-base transparent transparent;
	}

	&::after {
		background: $blue-base;
	}
}

[data-tooltip-color='red'] {
	&::before {
		border-color: $red-base transparent transparent;
	}

	&::after {
		background: $red-base;
	}
}

[data-tooltip-color='yellow'] {
	&::before {
		border-color: $yellow-base transparent transparent;
	}

	&::after {
		background: $yellow-base;
	}
}

[data-tooltip-color='green'] {
	&::before {
		border-color: $green-base transparent transparent;
	}

	&::after {
		background: $green-base;
	}
}


// Add `tooltip-dev` attribute during development
// to force a tooltip to be always visible 
[data-tooltip-dev] {
	&[data-tooltip] {
		&::before,
		&::after {
			opacity: 1 !important;
		}
	}
}


[disabled] {
	&[data-tooltip] {
		&::before,
		&::after {
			opacity: 0 !important;
		}
	}
}
