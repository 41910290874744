$dashboard-button-background: scale-color($button-background, $lightness: 5%);
$dashboard-button-background-hover: scale-color($button-background-hover, $lightness: 5%);

.px-dashboard {
	margin-bottom: 1.5rem;
	font-size: 0.9rem;

	.title {
		font-weight: 700;
	}

	.disabled {
		color: $silver-base;
	}

	.button {
		background-color: $dashboard-button-background;

		&:hover {
			background-color: $dashboard-button-background-hover;
		}

		&.primary {
			background-color: $button-primary-background;

			&:hover {
				background-color: $button-primary-background-hover;
			}
		}

		&.button-icon {
			background: transparent;
		}

		&:not([tooltip]) {
			margin-bottom: 0;
		}
	}

	table {
		margin-top: -0.375rem;
		width: 100%;
		table-layout: fixed;
		border-collapse: separate;
		border-spacing: 0 0.375rem;
		color: $white;

		&.align-middle {
			td {
				vertical-align: middle;
			}
		}

		&.fixed-header {
			position: relative;
			width: 100%;

			th {
				position: sticky;
				top: 0;
				background: $midnight-base;
				height: 3rem;
			}
		}
	}

	th,
	td {
		&.align-center {
			text-align: center;
		}
	}

	th {
		padding: 0.25rem 1rem;
		color: $silver-base;
		font-size: 0.9rem;
		font-weight: 700;
	}

	td {
		background: $dashboard-item-bg;
		padding: 1rem;
		vertical-align: top;

		&:first-child {
			border-top-left-radius: $global-radius;
			border-bottom-left-radius: $global-radius;
		}

		&:last-child {
			border-top-right-radius: $global-radius;
			border-bottom-right-radius: $global-radius;
		}

		&.align-middle {
			vertical-align: middle;
		}

		&.ellipsis {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&.has-link {
			padding: 0;

			a {
				display: block;
				padding: 1rem;
				color: inherit;
			}
		}
	}

	tr {
		td {
			transition: background-color 0.2s;
		}

		&:hover {
			td {
				background: lighten($dashboard-item-bg, 2%);
			}
		}
	}

	section {
		margin-bottom: 2.5rem;
	}
}
