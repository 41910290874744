$global-margin: 1rem;
$global-padding: 1rem;
$global-radius: 0.25rem;

$base-font-family: 'SF Text', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;

* {
	// sass-lint:disable-block no-vendor-prefixes
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.hide {
	// sass-lint:disable-block no-important
	display: none !important;
}

.invisible {
	// sass-lint:disable-block no-important
	visibility: hidden !important;
}

body {
	margin: 0;
	background: $body-background;
	padding: 0;
	line-height: 1.6;
	color: $body-font-color;
	font-family: $base-font-family;
	font-weight: normal;
}

img {
	max-width: 100%;
}

.clickable {
	cursor: pointer;
}

// sass-lint:disable no-important
[role='tooltip'] {
	z-index: 4 !important;
}

#birdeatsbug-sdk {
	z-index: 10000000000;
	right: 7rem !important;
}

// .intercom-lightweight-app-launcher {
// 	right: 2rem !important;
// 	bottom: 6rem !important;
// }

// .intercom-messenger-frame {
// 	right: 2rem !important;
// 	bottom: 11rem !important;
// 	max-height: 60rem !important;
// }

// .intercom-dfosxs {
// 	right: 2rem !important;
// 	bottom: 6rem !important;
// }

// .intercom-lightweight-app-messenger {
// 	right: 2rem !important;
// 	bottom: 11rem !important;
// }
