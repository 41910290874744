.px-media-item {
	display: grid;
	grid-template-columns: 1fr 2fr;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

.px-media-item-image {
	@include aspect-ratio(16, 9);
	border-radius: 0.125rem;
	background-color: rgba($white, 0.5);
	background-position: center center;
	background-size: cover;
	width: 100%;
	overflow: hidden;
}

.px-media-item-content {
	padding: 0 0.75rem;
}

.px-media-item-title {
	// sass-lint:disable-block no-duplicate-properties, no-vendor-prefixes
	display: -webkit-box;
	margin-bottom: 0.25rem;
	overflow: hidden;
	color: $white;
	font-size: 0.8rem;
	font-weight: 700;
	-webkit-line-clamp: 2;
}

.px-media-item-body {
	// sass-lint:disable-block no-duplicate-properties, no-vendor-prefixes
	display: -webkit-box;
	overflow: hidden;
	color: rgba($white, 0.8);
	font-size: 0.8rem;
	font-weight: 500;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

