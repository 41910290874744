.px-wrq {
	display: grid;
	grid-template-columns: auto 1fr;
	height: 100vh;

	h5 {
		font-weight: 700;
	}
}

.px-wrq-form {
	padding-right: 23rem;
	height: 100vh;
	overflow-y: auto;
}

.px-wrq-assets {
	display: grid;
	grid-template-rows: auto auto 1fr;
	padding: 1rem 1.5rem;
	width: 20rem;
	height: 100vh;

	@include breakpoint(1280) {
		width: 24rem;
	}
}

.px-wrq-assets-list-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;

	h6 {
		margin-bottom: 0;
		font-weight: 700;
	}

	button {
		transform: scale(0.9);
	}
}

.px-wrq-assets-list {
	overflow-y: auto;
}

.px-wrq-assets-list-grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 1rem;
}

.px-wrq-clear-queue {
	transition: opacity 150ms;
	opacity: 0.5;
	width: 1rem;
	height: 1rem;
	fill: $white;

	&:hover {
		opacity: 1;
	}
}

.px-wrq-order-receipt {
	display: grid;
	position: fixed;
	top: 0;
	right: 3rem;
	bottom: 0;
	grid-template-rows: auto 1fr auto;
	grid-gap: 1rem;
	background: $midnight-lighten-5;
	padding: 1rem 1.5rem;
	width: 20rem;
	height: 100vh;

	@include breakpoint(1280) {
		width: 24rem;
	}
}

.px-wrq-order-receipt-title {
	.px-page-title {
		margin-bottom: 0;
	}
}

.px-wrq-order-error {
	margin: 1rem 0;
	color: $color-error;
}

.px-wrq-place-order {
	margin: 0;
	width: 100%;
}

.px-wrq-empty-state {
	margin-top: 2rem;

	.px-empty-state {
		p {
			font-size: 2rem;

			svg {
				display: inline-block;
				margin-right: 1rem;
				margin-bottom: 0.25rem;
				width: 2em;
				height: 2rem;
				vertical-align: middle;

				path {
					fill: $medium-gray;
				}
			}
		}
	}
}
