@import 'sidebar';

.px-project-crud {
	margin: 1.75rem 7.875rem 1.75rem 1.5rem; // TODO: Calculate this. Based on button size and page margins/padding

	.px-spinner {
		margin-top: 4rem;
	}

	.text {
		color: $body-text;
	}

	.title {
		font-weight: 700;
	}

	.information-grid {
		display: grid;
		grid-template-columns: 7fr 3fr;
		grid-column-gap: 4rem;

		label {
			color: $medium-gray;
		}
	}

	.billing-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 4rem;
	}

	.billing {
		grid-column-end: span 2;
	}

	.information {
		margin-bottom: 2rem;

		input {
			margin-bottom: 0.5rem;
		}
	}

	.billing-section {
		margin: 1rem 0;
	}

	.billing-entry {
		display: grid;
		grid-template-columns: auto 3rem 10rem 3rem;
		margin: 1rem 0;

		.text {
			line-height: 2;
			color: $body-text;
		}

		.dollars {
			// sass-lint:disable-block no-vendor-prefixes
			margin: 0;
			border-radius: 0;
			width: initial;

			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				appearance: none;
			}
		}

		.dollar-sign {
			display: flex;
			align-items: center;
			justify-content: center;
			border-top-left-radius: 0.25rem;
			border-bottom-left-radius: 0.25rem;
			background-color: $dark-gray;
			color: $body-text;
		}

		.cents {
			display: flex;
			align-items: center;
			justify-content: center;
			border-top-right-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;
			background-color: $dark-gray;
			color: $body-text;
		}
	}

	.form-operations {
		position: fixed;
		right: 0;
		bottom: 0;
		margin: 1rem;
		padding: 0.875rem;

		svg {
			width: 1.5rem;
			height: 1.5rem;

			path {
				fill: $white;
			}
		}

		.button {
			display: block;
			box-shadow: 0 0.125rem 0.25rem rgba($black, 0.875);
		}

		.cancel {
			background-color: $alert-color;
		}
	}
}
