// sass-lint:disable no-mergeable-selectors

$button-padding: 0.85rem 1.5rem;
$button-line-height: 0.8rem;
$button-font-size: 0.9rem;

@mixin px-button-sizing {
	padding: $button-padding;
	line-height: $button-line-height;
	font-size: $button-font-size;
}

$button-background: $midnight-lighten-15;
$button-background-hover: $midnight-lighten-20;

$button-primary-background: $blue-base;
$button-primary-background-hover: $blue-darken-10;

.button {
	@include px-button-sizing;
	display: inline-block;
	transition: background-color 0.25s ease-out, color 0.25s ease-out;
	margin: 0 0 1rem;
	border: 0;
	border-radius: $global-radius;
	background-color: $button-background;
	cursor: pointer;
	vertical-align: middle;
	text-align: center;
	color: $white;
	font-family: inherit;
	font-weight: 500;

	&:hover {
		background-color: $button-background-hover;
	}

	&.small {
		padding: 0.5rem 1.25rem;
		font-size: 0.875rem;
	}

	&.large {
		border-radius: 0.3rem;
		padding: 0.625rem 1.25rem;
		line-height: 1.5;
		font-size: 1.25rem;
	}

	&.block {
		display: block;
	}

	&.primary {
		background-color: $button-primary-background;

		&:hover {
			background-color: $button-primary-background-hover;
		}
	}

	&.error {
		background-color: $red-base;

		&:hover {
			background-color: $red-darken-10;
		}
	}

	&:focus {
		outline: 0;
	}

	&.disabled,
	&[disabled] {
		opacity: 0.25;
		cursor: not-allowed;
	}

	&.button-icon-label {
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;

		svg {
			margin-right: 0.5rem;
			width: 1rem;
			height: 1rem;

			use,
			path {
				fill: $white;
			}
		}

		&:hover {
			color: $white;
		}

		&.small {
			svg {
				width: 0.75rem;
				height: 0.75rem;
			}
		}
	}
}

a {
	&.button {
		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

.button-group {
	display: inline-flex;
	margin-bottom: 1rem;

	.button {
		flex: 0 0 auto;
		transition: background-color 0.1s ease-out, color 0.1s ease-out, border 0.1s ease-out;
		margin: 0;
		border-radius: 0;
		background-color: $button-background;
		color: $white;
		font-weight: 700;

		&:hover {
			background-color: $button-background-hover;
		}

		&.active {
			border-radius: 0;
			background-color: $button-primary-background;

			&:hover {
				background-color: $button-primary-background-hover;
			}
		}

		&:first-child {
			border-top-left-radius: $global-radius;
			border-top-right-radius: 0;
			border-bottom-left-radius: $global-radius;
			border-bottom-right-radius: 0;
		}

		&:last-child {
			border-top-left-radius: 0;
			border-top-right-radius: $global-radius;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: $global-radius;
		}

		&:only-child {
			border-radius: $global-radius;
		}
	}

	&.button-group-radio {
		input {
			display: none;

			& + label {
				margin-right: 1px;

				&:last-child {
					margin-right: 0;
				}

				&:nth-child(2) {
					border-top-left-radius: $global-radius;
					border-bottom-left-radius: $global-radius;
				}
			}

			&:checked {
				& + label {
					background-color: $button-background;
					color: $white;
				}
			}
		}
	}
}

.button-icon {
	display: flex;
	align-items: center;
	background: transparent;
	padding: 0.75rem;

	svg {
		width: 1rem;
		height: 1rem;

		use,
		path {
			fill: $white;
		}
	}

	&:hover {
		background: transparent;
	}

	&[tooltip] {
		margin: 0.25rem 0;
		padding: 0.5rem 0.75rem;
	}
}
