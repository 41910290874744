// SF UI Display

@font-face {
	font-family: 'SF Display';
	font-weight: 900;
	src: url('/fonts/SF-UI-Display-Black.otf');
}

@font-face {
	font-family: 'SF Display';
	font-weight: 700;
	src: url('/fonts/SF-UI-Display-Bold.otf');
}

@font-face {
	font-family: 'SF Display';
	font-weight: 800;
	src: url('/fonts/SF-UI-Display-Heavy.otf');
}

@font-face {
	font-family: 'SF Display';
	font-weight: 200;
	src: url('/fonts/SF-UI-Display-Light.otf');
}

@font-face {
	font-family: 'SF Display';
	font-weight: 500;
	src: url('/fonts/SF-UI-Display-Medium.otf');
}

@font-face {
	font-family: 'SF Display';
	font-weight: 400;
	src: url('/fonts/SF-UI-Display-Regular.otf');
}

@font-face {
	font-family: 'SF Display';
	font-weight: 600;
	src: url('/fonts/SF-UI-Display-Semibold.otf');
}

@font-face {
	font-family: 'SF Display';
	font-weight: 300;
	src: url('/fonts/SF-UI-Display-Thin.otf');
}

@font-face {
	font-family: 'SF Display';
	font-weight: 100;
	src: url('/fonts/SF-UI-Display-Ultralight.otf');
}

// SF UI Text

@font-face {
	font-family: 'SF Text';
	font-weight: 700;
	src: url('/fonts/SF-UI-Text-Bold.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 700;
	font-style: italic;
	src: url('/fonts/SF-UI-Text-BoldItalic.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 800;
	src: url('/fonts/SF-UI-Text-Heavy.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 800;
	font-style: italic;
	src: url('/fonts/SF-UI-Text-HeavyItalic.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 200;
	src: url('/fonts/SF-UI-Text-Light.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 200;
	font-style: italic;
	src: url('/fonts/SF-UI-Text-LightItalic.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 500;
	src: url('/fonts/SF-UI-Text-Medium.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 500;
	font-style: italic;
	src: url('/fonts/SF-UI-Text-MediumItalic.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 400;
	src: url('/fonts/SF-UI-Text-Regular.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 400;
	font-style: italic;
	src: url('/fonts/SF-UI-Text-RegularItalic.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 600;
	src: url('/fonts/SF-UI-Text-Semibold.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 600;
	font-style: italic;
	src: url('/fonts/SF-UI-Text-SemiboldItalic.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 100;
	src: url('/fonts/SF-UI-Text-Ultrathin.otf');
}

@font-face {
	font-family: 'SF Text';
	font-weight: 100;
	font-style: italic;
	src: url('/fonts/SF-UI-Text-UltrathinItalic.otf');
}

// SF Mono

@font-face {
	font-family: 'SF Mono';
	font-weight: 700;
	src: url('/fonts/SFMono-Bold.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 700;
	font-style: italic;
	src: url('/fonts/SFMono-BoldItalic.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 800;
	src: url('/fonts/SFMono-Heavy.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 800;
	font-style: italic;
	src: url('/fonts/SFMono-HeavyItalic.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 200;
	src: url('/fonts/SFMono-Light.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 200;
	font-style: italic;
	src: url('/fonts/SFMono-LightItalic.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 500;
	src: url('/fonts/SFMono-Medium.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 500;
	font-style: italic;
	src: url('/fonts/SFMono-MediumItalic.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 400;
	src: url('/fonts/SFMono-Regular.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 400;
	font-style: italic;
	src: url('/fonts/SFMono-RegularItalic.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 600;
	src: url('/fonts/SFMono-Semibold.otf');
}

@font-face {
	font-family: 'SF Mono';
	font-weight: 600;
	font-style: italic;
	src: url('/fonts/SFMono-SemiboldItalic.otf');
}

// Language-specific fonts

@font-face {
	font-family: 'Arial';
	font-style: normal;
	src: url('/fonts/arial.woff') format('woff');
}

@font-face {
	font-family: 'Arial';
	font-style: italic;
	src: url('/fonts/arial-italic.woff') format('woff');
}

@font-face {
	font-family: 'Arial Unicode MS';
	src: url('/fonts/arialuni.woff') format('woff');
}

@font-face {
	font-family: 'SimHei';
	src: url('/fonts/simhei.woff') format('woff');
}

@font-face {
	font-family: 'Angsa';
	src: url('/fonts/angsa.woff') format('ttf');
}

@font-face {
	font-family: 'Narkisim';
	src: url('/fonts/narkisim.woff') format('woff');
}

@font-face {
	font-family: 'NanumGothic';
	src: url('/fonts/nanum-gothic.woff') format('woff');
}

@font-face {
	font-family: 'Gulim';
	src: url('/fonts/gulim.woff') format('woff');
}

@font-face {
	font-family: 'KokuzaGothic';
	src: url('/fonts/kokuza-gothic.woff') format('woff');
}
