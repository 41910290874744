$font-path: '/react-widgets/fonts';
$input-bg: 'initial';
$input-bg-color: 'initial';
$input-border: 0;
$input-focus-box-shadow: 0;
$state-bg-hover: 'initial';

@import '../../node_modules/react-widgets/lib/scss/react-widgets';

.rw-widget-container {
	input {
		margin: 0;
	}

	.rw-btn {
		color: $silver-lighten-10;

		&:hover {
			color: $white;
		}
	}

	.rw-select {
		padding-left: 0.5rem;
	}

	&.invalid {
		input {
			border-color: rgba($red-base, 0.67);
		}
	}
}

.rw-datetime-picker {
	&.invalid {
		input {
			border-color: rgba($red-base, 0.67);
		}
	}
}

.rw-state-disabled {
	& > .rw-widget-picker {
		background: transparent;
	}
}

.rw-input {
	&[disabled] {
		background-color: $midnight-base;
	}
}
