html {
	font-size: 12px;

	@include breakpoint(1400) {
		font-size: 13px;
	}

	@include breakpoint(1500) {
		font-size: 14px;
	}

	@include breakpoint(1600) {
		font-size: 16px;
	}

	@include breakpoint(2240) {
		font-size: 18px;
	}
}
