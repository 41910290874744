.px-progress-circle-base {
	stroke: $darkest-gray;
	stroke-width: 35;
	fill: none;
}

.px-progress-circle-fill {
	stroke: $color-primary-light;
	stroke-dashoffset: 1100;
	stroke-dasharray: 1100;
	stroke-linecap: round;
	stroke-width: 35;
	fill: none;
}
