.ng-toast {
	.fade {
		transition: opacity 0.15s linear;
		opacity: 0;

		.in {
			opacity: 1;
		}
	}

	.alert-info {
		border-color: $color-primary-dark;
		background-color: $color-primary-light;
		color: $white;
	}

	.alert-warn,
	.alert-warning {
		background-color: $color-warning;
		color: $white;
	}

	.alert-error {
		background-color: $color-error;
		color: $white;
	}

	.alert {
		margin-bottom: 20px;
		border: 1px solid transparent;
		border-radius: 4px;
		padding: 15px;
	}

	.close {
		float: right;
		opacity: 0.2;
		text-shadow: 0 1px 0 $white;
		line-height: 1;
		color: $black;
		font-size: 21px;
		font-weight: 700;
		filter: alpha(opacity=20);
	}

	a {
		text-decoration: none;
		color: $white;
	}
}

.px-asset-action-toast-action {
	margin-left: 2rem;
	cursor: pointer;
	font-variant: small-caps;

	&:hover {
		text-decoration: underline;
	}
}
