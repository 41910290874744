@mixin word-wrap {
	word-break: break-word;
	word-wrap: break-word;
	overflow-wrap: break-word;
	hyphens: auto;
}

@mixin ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin aspect-ratio($width, $height) {
	display: block;
	padding-top: ($height / $width) * 100%;
	width: 100%;
	height: 0;
}

// Animation

@mixin keyframe($animation-name) {
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($animation-name, $delay, $duration, $iterations, $easing) {
	animation-name: $animation-name;
	animation-duration: $duration;
	animation-timing-function: $easing;
	animation-delay: $delay;
	animation-iteration-count: $iterations;
	animation-fill-mode: forwards; // this prevents the animation from restarting!
}

@mixin breakpoint($breakpoint) {
	$bp: $breakpoint + 'px';

	@if $breakpoint == 'medium' {
		$bp: 640px;
	} @else if $breakpoint == 'large' {
		$bp: 1024px;
	} @else if $breakpoint == 'xlarge' {
		$bp: 1200px;
	} @else if $breakpoint == 'xxlarge' {
		$bp: 1440px;
	}

	@media (min-width: #{$bp}) {
		@content;
	}
}
