// sass-lint:disable no-vendor-prefixes

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
	display: block;
	transition: border-color 0.2s;
	border: 3px solid $silver-darken-15;
	border-radius: $global-radius;
	box-shadow: 0;
	background-color: $midnight-base;
	padding: 1rem;
	width: 100%;
	height: 2.75rem;
	line-height: 1.5;
	color: $white;
	font-family: inherit;
	font-size: 1rem;
	font-weight: normal;
	appearance: none;

	&:focus {
		outline: 0;
		border-color: rgba($blue-base, 0.67);
	}

	&:disabled,
	&[read-only] {
		opacity: 0.33;
	}
}

input {
	&[type='text'],
	&[type='email'],
	&[type='password'] {
		&.invalid,
		&.is-invalid {
			border-color: rgba($red-base, 0.67);
		}
	}

	&[type='number'] {
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			margin: 0;
			-webkit-appearance: none;
		}
	}
}

select {
	@include px-button-sizing;
	box-sizing: border-box;
	transition: border-color 0.2s, background-image 0.1s;
	outline: none;
	border: 3px solid $silver-darken-15;
	border-radius: $global-radius;
	box-shadow: 0;
	background-color: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='24' height='24' viewBox='0 0 24 24'><path fill='%23666666' d='M22,5.7H2c-1.1,0-2,0.9-2,2c0,0.4,0.1,0.7,0.3,1l10,8.6c0.6,0.9,1.8,1.2,2.8,0.6c0.3-0.2,0.5-0.4,0.6-0.6l10-8.6 C24.3,7.8,24,6.6,23.1,6C22.7,5.8,22.4,5.7,22,5.7z'/></svg>"); // sass-lint:disable-line quotes
	background-repeat: no-repeat;
	background-position: right -1.5rem center;
	background-size: 0.75rem 0.75rem;
	padding: 0.5rem 2.5rem 0.5rem 1rem;
	width: 100%;
	max-width: 100%;
	height: auto;
	line-height: 1.625;
	color: $white;
	font-size: 1rem;
	font-weight: 400;
	appearance: none;
	background-origin: content-box;

	&:hover {
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='24' height='24' viewBox='0 0 24 24'><path fill='%23ffffff' d='M22,5.7H2c-1.1,0-2,0.9-2,2c0,0.4,0.1,0.7,0.3,1l10,8.6c0.6,0.9,1.8,1.2,2.8,0.6c0.3-0.2,0.5-0.4,0.6-0.6l10-8.6 C24.3,7.8,24,6.6,23.1,6C22.7,5.8,22.4,5.7,22,5.7z'/></svg>"); // sass-lint:disable-line quotes
	}

	&:focus {
		outline: none;
		border-color: rgba($blue-base, 0.67);
	}
}

textarea {
	max-width: 100%;
	resize: none;
	line-height: 1.4rem;

	&[rows] {
		height: auto;
	}
}

input,
textarea {
	&::placeholder {
		color: $medium-dark-gray;
	}
}

label {
	display: block;
	margin: 0 0 0.5rem;
	line-height: 1.5;
	color: $medium-light-gray;
	font-size: 0.9rem;
	font-weight: normal;
}

.input-group {
	margin-bottom: 1rem;

	.input-group-label {
		background-color: $dark-gray;
		color: $text-light;

		i {
			line-height: 1rem;
			font-size: 1rem;
		}
	}

	input {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
