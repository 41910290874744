$notification-badge-size: 1.25rem;
$notification-badge-font-size: $notification-badge-size / 2;
$notification-badge-offset: -$notification-badge-size / 2;
$badge-background: $blue-base;

.has-badge {
	position: relative;
}

.badge {
	display: inline-block;
	position: absolute;
	top: $notification-badge-offset;
	right: $notification-badge-offset;
	border-radius: 50%;
	background-color: $badge-background;
	padding: 0.3125rem;
	min-width: 2.1em;
	text-align: center;
	line-height: 1;
	color: $white;
	font-size: $notification-badge-font-size;
	font-weight: bold;

	&.right-middle {
		top: 50%;
		right: 0.5rem;
		margin-top: $notification-badge-offset;
	}

	&.right-top {
		top: 0;
		right: 0;
	}
}

.inline-badge {
	box-sizing: border-box;
	margin-left: 0.5rem;
	border-radius: 0.125rem;
	background-color: $badge-background;
	padding: 1px 0.375rem;
	min-width: 3rem;
	height: 1rem;
	text-align: center;
	line-height: inherit;
	color: $white;
	font-size: 0.7rem;
}
