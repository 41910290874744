// sass-lint:disable no-important, property-sort-order, no-transition-all, no-vendor-prefixes

$vac-primary-color: #00a6c2;		// Primary color, used for buttons and toggle colors
$vac-secondary-color: #eed428;	// Secondary color, used for highlight elements and markers
$vac-active-color: #ee9828;		  // Active color, used for annotation shapes & marker interaction
$vac-white: #fff;
$vac-black: #000;
$vac-red: #f00;
$vac-orange: rgb(255, 165, 0);

$vac-med-gray: #dbdcdc;
$vac-dark-gray: #383838;

$ui-class-hidden: '.vac-hidden';
$ui-class-active: '.vac-active';
$ui-class-play-disabled: '.vac-disable-play';

// NOTES
// Videojs standard elements are prefixed with 'vjs-'*
// Elements added by videojs-annotation-comments are prefixed with 'vac-'*

// Scope to video-js player element
.video-js {
	// Applied when annotations are toggled 'on'
	&#{$ui-class-active} {
		// Hide timeline status and large play button
		.vjs-mouse-display,
		.vjs-big-play-button {
			display: none !important;
		}

		// Display outer wrap for timeline markers & annotation control elements
		.vac-marker-owrap,
		.vac-control {
			display: block;
		}

		// Set low z-index for vjs controls
		.vjs-control-bar {
			z-index: 2;
		}

		// Toggle color of annotation enable button in controls
		.vac-player-btn {
			display: none;
		}

		// Only display annotation shapes when controlbar is shown (vjs has started)
		&.vjs-has-started {
			.vac-shape {
				display: block;
			}
		}
	}

	// Applied during adding annotations state
	&#{$ui-class-play-disabled} {
		.vjs-control {
			cursor: default;
		}
	}

	// Generic class to hide elements
	#{$ui-class-hidden} {
		display: none !important;
	}

	&.vac-disable-fullscreen {
		.vac-player-btn {
			display: none;
		}
	}

	// Class applied when video play controls are disabled
	&.vac-disable-play {
		// Hide individual markers
		.vac-marker {
			display: none !important;
		}

		// Make vjs progress/controls invisible
		.vjs-play-control,
		.vjs-play-progress {
			visibility: hidden !important;
		}
	}

	// Standard button class
	.vac-button {
		transition: all 0.2s ease-in-out;
		margin: 10px;
		line-height: 27px;
		font-size: 13px;
		padding: 0;
		width: calc(100% - 20px);
		text-align: center;
		border-radius: 2px;
		background: $vac-primary-color;
		color: $vac-white;
		display: block;
		cursor: pointer;

		&:hover {
			background-color: lighten($vac-primary-color, 15%);
		}
	}

	// Tinted overlay cover visible when adding new annotations (prevents user click on video)
	.vac-video-cover {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($blue-base, 0.2);
		cursor: crosshair;

		.vac-video-cover-canvas {
			width: 100%;
			height: 100%;
			position: relative;
		}
	}

	// Wrapper class for control elements displayed during 'add new annotation' final step,
	// where 'write comment' box is displayed AND when adding a new comment to an existing annotation
	.vac-video-write-new-wrap {
		display: none !important;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 11;
		background-color: rgba($vac-black, 0.7);

		&.vac-new-comment {
			background-color: rgba($vac-black, 0.4);
		}

		.vac-video-write-new {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			& > div {
				width: 80%;
				max-width: 400px;
				border-radius: 2px;
				background: rgba($vac-white, 0.85);
				padding: 20px;

				h5 {
					margin: 0;
					padding: 0;
					font-size: 15px;
					color: $vac-black;
					font-weight: normal;
				}
			}

			// Wrapper div around textarea and save/cancel controls
			.vac-comment-showbox {
				border-radius: 1px;
				background-color: $vac-white;
				margin-top: 20px;
				width: 100%;
				border-bottom: 2px solid $vac-active-color;

				textarea {
					border: 0;
					font-size: 13px;
					color: $vac-dark-gray;
					line-height: 16px;
					padding: 8px;
					box-sizing: border-box;
					min-height: 110px;
					width: 100%;
					resize: none;
				}

				& > div {
					padding: 10px;
					box-sizing: content-box;
					height: 27px;

					// 'Save' button
					button {
						float: right;
						width: auto;
						padding: 0 40px;
						margin: 0;
					}

					// Cancel link
					a {
						float: right;
						font-size: 12px;
						text-decoration: underline;
						line-height: 27px;
						color: $vac-dark-gray;
						cursor: pointer;
						margin-right: 20px;

						&:hover {
							color: $vac-red;
						}
					}
				}
			}

			&.vac-is-comment {
				display: block;

				// Box is positioned via javascript on add to overlap exactly with underlying positioned box
				.vac-comment-showbox {
					position: absolute;
					padding: 0;
					margin: 0;
					max-width: none;
				}
			}
		}
	}

	// Annotation 'shape' element drawn on top of video - positioned by javascript
	.vac-shape {
		position: absolute;
		z-index: 1;
		background-color: rgba($blue-base, 0.65);
		border: 2px dotted $blue-base;
		display: none;
	}

	// Control elements ('Add new box', etc)
	.vac-controls,
	.vac-add-controls {
		z-index: 3;
		position: absolute;
		border-radius: 2px;
		overflow: hidden;
		background-color: rgba($vac-black, 0.4);
		top: 4px;
		left: 4px;
		padding: 4px 0 0 ;
		font-size: 14px;
		color: $vac-white;
		width: 150px;
		display: none !important;
		text-align: center;

		// Subtext
		i {
			color: $vac-med-gray;
			font-size: 11px;
			font-style: normal;
			display: block;
			padding: 4px 0 0;
		}

		button {
			transition: all 0.2s ease-in-out;
			margin: 10px;
			line-height: 27px;
			font-size: 13px;
			padding: 0;
			width: calc(100% - 20px);
			text-align: center;
			border-radius: 2px;
			background: $vac-primary-color;
			color: $vac-white;
			display: block;
			cursor: pointer;

			&:hover {
				background-color: lighten($vac-primary-color, 15%);
			}
		}

		// Wrapper for button controls (save, cancel, continue)
		&.vac-add-controls {
			button {
				background-color: $vac-secondary-color;
				color: $vac-dark-gray;

				&:hover {
					background-color: lighten($vac-secondary-color, 15%);
				}
			}

			a {
				margin: 3px auto 8px;
				text-transform: uppercase;
				display: inline-block;
				color: $vac-white;
				cursor: pointer;
				text-decoration: underline;
				font-size: 10px;

				&:hover {
					color: $vac-red;
				}
			}
		}

		// Wrapper for controls to go to <prev|next> annotation and to scrub +/- 1 sec
		.vac-annotation-nav,
		.vac-video-move {
			margin-top: 8px;
			width: 100%;
			display: flex;
			flex-direction: row;

			div {
				flex: 1;
				background-color: rgba($vac-black, 0.8);
				text-align: center;
				line-height: 22px;
				color: $vac-secondary-color;
				// transition: all 0.2s ease-in-out;
				cursor: pointer;
				font-size: 12px;

				&:hover {
					background-color: $vac-secondary-color;
					color: $vac-black;
				}

				&.vac-a-prev {
					margin-right: 1px;
				}
			}
		}
	}

	.vac-cursor-tool-tip {
		position: absolute;
		border-radius: 100%;
		z-index: 40;
		display: inline-block;
		white-space: nowrap;
	}

	// Button added to the control bar, which toggles annotation mode
	.vac-player-btn {
		display: none !important;
	}

	// Outwer wrap for area containing all timeline markers
	.vac-marker-owrap {
		display: none;
		z-index: 10;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		// Inner, relatively positioned wrapper for markers
		.vac-marker-wrap {
			margin: 0 10px;
			height: 100%;
			position: relative;
			flex: auto;

			// Actual marker element drawn on timeline (for both regular and draggable marker)
			// NOTE - marker is drawn as standard div but long-tail for range is drawn as a box-shadow
			.vac-marker,
			.vac-marker-draggable {
				position: absolute;
				width: 7px;
				min-width: 7px !important; // fixes issue of markers with no duration not appearing
				margin-left: 0;
				height: 30px;
				// bottom: 6px;
				background: $silver-base;
				border-radius: 2px;
				cursor: pointer;
				transition: all 0.2s ease-in-out;

				// When hoving or in 'force tooltip' state, show tooltip and highlight/enlarge
				&.vac-hovering,
				&.vac-force-tooltip {
					opacity: 1 !important;
					background: $blue-base;
					// height: 35px;

					& > div {
						display: block ;
						opacity: 1;
					}

					&.vac-ranged-markerb {
						box-shadow: 7px 0 0 0 $blue-base inset;
						background-color: rgba($blue-base, 0.65);
					}
				}

				// When a marker is active, highlight it appropriately
				&#{$ui-class-active} {
					background-color: $blue-base;

					&.vac-ranged-marker {
						box-shadow: 7px 0 0 0 $blue-base inset;
						background-color: rgba($blue-base, 0.65);
					}
				}

				// Range markers have 'tail' drawn with box shadow
				&.vac-ranged-marker {
					box-shadow: 7px 0 0 0 $silver-base inset;
					background-color: rgba($silver-base, 0.65);
				}

				// Ranged markers use different color than regular markers (and are always range markers)
				&.vac-marker-draggable {
					box-shadow: 7px 0 0 0 $yellow-base inset;
					background-color: rgba($yellow-base, 0.65);
					cursor: ew-resize;
					min-width: 7px !important;
				}

				// Tooltip wrapper for a marker
				& > div {
					width: 100%;
					position: relative;
					display: none;
					opacity: 0;
					transition: opacity 0.2s ease-in-out;

					// Actual tooltip element, which is positioned left/right based on what side of timeline it's in
					span {
						text-overflow: ellipsis;
						white-space: nowrap;
						border-bottom: 1px solid $blue-base;
						display: block;
						position: absolute;
						max-width: 250px;
						bottom: -1px;
						line-height: 26px;
						height: 28px;
						overflow: hidden;
						padding: 0 5px;
						box-sizing: border-box;
						border-radius: 1px;
						background: rgba($vac-black, 0.8);
						color: $vac-white;
						font-size: 12px;
						left: 0;

						// Time/range display
						b {
							color: $blue-lighten-10;
							display: inline-block;
							margin-right: 5px;
							font-weight: 600;
						}

						&.vac-right-side {
							left: auto;
							right: 0;
						}
					}
				}
			}

			// When hovering/active, dim all OTHER markers so active can be highlighted
			&.vac-dim-all {
				.vac-marker {
					height: 30px;
					opacity: 0.4;

					&.vac-force-tooltip {
						& > div {
							display: none;
						}
					}
				}
			}
		}
	}

	// Container element for comments in a displayed annotation
	.vac-comments-container {
		display: none !important;
		position: absolute;
		right: 0;
		top: 0;
		width: 15%;
		min-width: 225px;
		z-index: 11;
		background-color: rgba($vac-white, 0.6);
		height: calc(100% - 42px);
		box-sizing: border-box;
		transition: all 0.15s linear;

		// When hovering over or interacting with comments list, toggle opacity and slide out
		&:hover,
		&#{$ui-class-active} {
			width: 60%;
			max-width: 480px;

			.vac-comments-wrap {
				opacity: 1;
			}

			&#{$ui-class-active} {
				// Box used to position 'add new comment' textbox over
				.vac-add-new-shapebox {
					display: block;
				}

				.vac-reply-btn {
					display: none;
				}
			}
		}

		// Wrapper for list of comments
		.vac-comments-wrap {
			opacity: 0.9;
			height: calc(100% - 26px);
			overflow-x: hidden;
			overflow-y: auto;
			box-sizing: border-box;
			padding: 10px;
			border-left: 1px solid $vac-med-gray;

			// Style scrollbar in comments list
			&::-webkit-scrollbar {
				width: 6px;
				background-color: transparent;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 5px;
				box-shadow: inset 0 0 6px rgba($vac-black, 0.3);
				background-color: $vac-dark-gray;
			}

			// Individual comment in the comments list
			.vac-comment {
				border-radius: 1px;
				box-shadow: 2px 2px 5px 0 rgba($vac-black, 0.43);

				// Header of comment containing user name & timestamp
				.vac-comment-header {
					width: 100%;
					padding: 2px 5px;
					color: $vac-white;
					background-color: $vac-dark-gray;
					text-align: left;

					// Display delete button on hover
					&:hover {
						.vac-delete-comment {
							display: inline;
						}
					}

					.vac-author-name,
					.vac-timestamp {
						display: inline-block;
						line-height: 16px;

						&.vac-author-name {
							font-weight: bold;
							text-align: left;
							font-size: 11px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}

						&.vac-timestamp {
							float: right;
							text-align: right;
							font-size: 10px;
						}
					}

					.vac-delete-comment {
						display: none;
						cursor: pointer;
						color: $vac-red;
					}
				}

				// Main body of the comment
				.vac-comment-body {
					width: 100%;
					padding: 8px;
					box-sizing: border-box;
					margin-bottom: 10px;
					background-color: $vac-white;
					color: $vac-black;
					font-size: 13px;
					line-height: 16px;
					text-align: left;
					border-bottom-left-radius: 2px;
					border-bottom-right-radius: 2px;
					border-bottom: 1px solid lighten($vac-med-gray, 20%);
				}
			}

			// Replay to a comment button
			.vac-reply-btn {
				margin: 10px 0 0;
				width: 100%;
			}

			// This box is used for the javascript to position the overlay display for adding a new comment
			.vac-add-new-shapebox {
				margin: 10px 0 0;
				border-radius: 2px;
				height: 157px;
				padding: 0;
				color: $vac-orange;
				display: none;
			}
		}

		// Control bar displayed at the bottom in the comments list
		.vac-comments-control-bar {
			height: 26px;
			border-bottom: 1px solid $vac-secondary-color;
			line-height: 26px;
			padding: 0 10px;
			box-sizing: border-box;
			width: 100%;
			background: $vac-black;

			.vac-range,
			.vac-control-buttons {
				display: inline-block;
				width: 49%;

				&.vac-range {
					float: left;
					text-align: left;
					color: $vac-secondary-color;

					b {
						color: $vac-white;
					}
				}
			}

			.vac-control-buttons {
				float: right;
				text-align: right;
				font-size: 10px;

				a {
					margin: 0 4px;
					cursor: pointer;
					color: $vac-red;

					&.vac-close-comment-list {
						color: $vac-white;
					}

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
