// sass-lint:disable single-line-per-selector

$heading-font-family: 'SF Display', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
$heading-font-weight: 700;

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
legend {
	margin-bottom: 0.5rem;
	text-rendering: optimizeLegibility;
	color: inherit;
	font-family: $heading-font-family;
	font-weight: $heading-font-weight;

	small {
		margin: 0 0.5rem;
		font-size: 100%;
	}
}

h1, .h1,
h2, .h2 {
	line-height: 1.1;
}

h3, .h3,
h4, .h4 {
	line-height: 1.3;
}

h5, .h5,
h6, .h6,
legend {
	line-height: 1.4;
}

h1, .h1 {
	letter-spacing: -2px;
	font-size: 4rem;
}

h2, .h2 {
	letter-spacing: -1px;
	font-size: 2.5rem;
}

h3, .h3 {
	font-size: 2rem;
}

h4, .h4 {
	font-size: 1.5625rem;
}

h5, .h5,
legend {
	font-size: 1.25rem;
}

h6, .h6 {
	font-size: 1rem;
}

p {
	margin-bottom: 1rem;
	text-rendering: optimizeLegibility;
	line-height: 1.6;
	font-size: inherit;
}

a {
	cursor: pointer;
	text-decoration: none;
	line-height: inherit;
	color: $primary-color;
}

ul,
ol,
dl {
	margin-bottom: 1rem;
	list-style-position: outside;
	line-height: 1.6;
}

li {
	font-size: inherit;
}

ul,
ol {
	margin-left: 1.25rem;

	ul,
	ol {
		margin-bottom: 0;
		margin-left: 1.25rem;
	}
}

ul {
	list-style-type: disc;
}

dl {
	margin-bottom: 1rem;

	dt {
		margin-bottom: 0.3rem;
		font-weight: bold;
	}
}

.px-page-title {
	margin-bottom: 1rem;
	line-height: 1.5;
	letter-spacing: 0;
	font-size: 1.875rem;
	font-weight: 700;
}
