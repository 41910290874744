.sidebar {
	padding: 1.75rem;
	width: 16rem;

	.poster-container {
		position: relative;
		cursor: pointer;
		width: 100%;
		height: 18.5rem;

		.poster-loader {
			position: absolute;
			z-index: 1;
			width: 100%;
		}

		&.empty {
			outline: 0.125rem dashed $medium-gray;
		}

		&:hover {
			&:not(.empty) {
				.upload {
					z-index: 1;
					background-color: rgba($px-sidebar-background, 0.75);
				}
			}
		}
	}

	.info {
		margin: 1.5rem 0;
	}

	.poster {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.project-name {
		margin-bottom: 0.25rem;
		font-weight: 700;
	}

	.studio-name {
		color: $medium-gray;
		font-weight: 400;
	}

	.upload {
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		text-align: center;

		.icon {
			margin-bottom: 1.25rem;
			width: 2.5rem;
			height: 2.5rem;
			fill: $medium-gray;
		}

		.text {
			color: $medium-gray;
		}
	}
}
