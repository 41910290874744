$px-top-bar-height: 4rem;
$px-main-content-gutter: 1.5rem;

$px-menu-background: $midnight-lighten-5;
$px-sidebar-background: transparent;

body {
	overflow: hidden;
}

.px-grid {
	display: grid;
	position: relative;
	grid-template: 100vh / auto auto 1fr;

	.px-menu {
		display: grid;
		grid-template-rows: auto 1fr auto;
		background: $px-menu-background;
		height: 100vh;
	}

	.px-main {
		overflow-x: hidden;
		overflow-y: auto;
	}

	.px-sidebar {
		background: $px-sidebar-background;
	}

	.px-notifications {
		position: absolute;
		grid-column: 2 / -1;
		grid-row: 1 / -1;
		z-index: 80;
		width: 100%;
		height: 100%;
		overflow: hidden;
		pointer-events: none;
	}
}

// CSS hack to hide duplicate view when navigating to different state
[ui-view='content'] + [ui-view='content'] {
	display: none;
}
