.px-radio-button {
	display: inline-block;
	position: relative;
	cursor: pointer;
	padding-left: 2rem;
	line-height: inherit;
	color: $body-text;
	user-select: none;

	&:hover {
		color: $white;

		.px-radio-button-indicator {
			border-color: $white;
		}
	}
}

.px-radio-button-label {
	font-size: 1rem;
}

.px-radio-button-input {
	position: absolute;
	opacity: 0;
	cursor: pointer;

	&:checked {
		~ .px-radio-button-indicator {
			border: 2px solid $primary-color;

			&::after {
				display: block;
			}
		}
	}
}

.px-radio-button-indicator {
	position: absolute;
	top: 0;
	left: 0;
	border: 2px solid $body-text;
	border-radius: 50%;
	width: 25px;
	height: 25px;

	&::after {
		display: none;
		position: absolute;
		top: 20%;
		left: 20%;
		border-radius: 50%;
		background-color: $primary-color;
		width: 60%;
		height: 60%;
		content: '';
	}
}
