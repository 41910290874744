// sass-lint:disable no-important, no-vendor-prefixes, no-misspelled-properties

html {
	scrollbar-face-color: $medium-dark-gray;
	scrollbar-base-color: $medium-dark-gray;
	scrollbar-highlight-color: $medium-dark-gray;
	scrollbar-track-color: $body-background;
	scrollbar-arrow-color: $body-background;
	scrollbar-shadow-color: $medium-dark-gray;
}

::-webkit-scrollbar {
	width: 7px;
}

::-webkit-scrollbar-button {
	background-color: $medium-dark-gray;
}

::-webkit-scrollbar-track {
	background-color: $medium-dark-gray;
}

::-webkit-scrollbar-track-piece {
	background-color: $body-background;
}

::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: rgba($medium-dark-gray, 0.5);
}

::-webkit-scrollbar-corner {
	background-color: $medium-dark-gray;
}

::-webkit-resizer {
	background-color: $medium-dark-gray;
}

* {
	// Firefox
	scrollbar-color: dark;
	scrollbar-width: thin;
}
