.px-asset-preview-embargo {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	background-color: $darkest-gray;
	padding: 1.5rem;
	width: 100%;
	height: 100%;
}

.px-asset-preview-embargo-text {
	text-align: center;
	white-space: pre-wrap;
	color: $white;
	font-size: 1.5rem;
}

.px-asset-preview-embargo-notes-btn {
	position: absolute;
	right: 1.5em;
	bottom: 1.5em;
	margin: 0;
	background-color: $dark-gray;

	&:hover {
		background-color: $button-background-hover;
	}

	svg {
		path {
			fill: $white;
		}
	}
}
