$px-filter-height: 5rem;
$px-filter-info-height: 3rem;
$px-projects-top-height: $px-filter-height + $px-filter-info-height;

// Each key:value pair is a breakpoint paired with how many projects to show per row
$projects-row-map: (
	640: 4,
	900: 5,
	1200: 6,
	1900: 8,
	2400: 9
);

#projects {
	display: grid;
	position: relative;
	grid-template-rows: $px-filter-height $px-filter-info-height 1fr;
	padding-right: 0; // space for feedback button

	.px-projects-filter {
		display: flex;
		align-items: center;
		flex-flow: row nowrap;
		justify-content: flex-start;
		padding: 0 1.5rem;
		height: $px-filter-height;

		.px-search-bar {
			width: 30rem;
		}
	}

	.menu {
		li {
			a {
				svg {
					width: 1.25rem;
					height: 1.25rem;

					path {
						fill: rgba($white, 0.5);
					}
				}

				&:hover {
					svg {
						path {
							fill: $white;
						}
					}
				}
			}
		}
	}

	.px-projects-filter-info {
		display: flex;
		align-items: flex-start;
		flex-flow: row;
		justify-content: flex-start;
		padding: 0 1.5rem;
		height: $px-filter-info-height;

		span {
			font-weight: 700;
		}

		.button {
			margin: 0 1rem;
			padding: 0.5em 1em;
			font-size: 0.75rem;
		}
	}

	.px-projects {
		height: calc(100vh - #{$px-projects-top-height});
		overflow-y: auto;
		will-change: transform;

		.px-projects-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 1rem;
			padding: 0 2rem 2rem 1.5rem;
			padding-top: 0.5rem;

			@include breakpoint(480) {
				grid-gap: 1.5rem;
			}

			@include breakpoint(1024) {
				grid-gap: 2rem;
			}

			@each $projects-bp, $projects-cnt in $projects-row-map {
				@include breakpoint($projects-bp) {
					grid-template-columns: repeat($projects-cnt, 1fr);
				}
			}
		}
	}

	.px-projects-create-btn {
		position: fixed;
		right: 0;
		bottom: 0;
		margin: 1rem;
		box-shadow: 0 0.125rem 0.25rem rgba($black, 0.875);
		padding: 0.875rem;

		svg {
			width: 1.5rem;
			height: 1.5rem;

			path {
				fill: $white;
			}
		}
	}
}

.px-projects-load-error-container {
	margin-top: 6rem;
}
