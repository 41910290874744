// sass-lint:disable class-name-format

.react-tags {
	position: relative;
	transition: border-color 0.2s;
	border: 3px solid $silver-darken-15;
	border-radius: $global-radius;
	box-shadow: 0;
	background-color: $midnight-base;
	cursor: text;
	padding: 0.25rem 0.75rem 0;
	line-height: 1.2;
	font-family: $base-font-family;
	font-size: 0.875rem;

	&.invalid {
		border-color: rgba($red-base, 0.67);
	}

	&.is-focused {
		outline: 0;
		border-color: rgba($blue-base, 0.67);
	}

	[tooltip] {
		&::after {
			min-width: 7rem;
		}
	}
}

.react-tags__selected {
	display: inline;
}

.react-tags__search {
	display: inline-block;
	margin-bottom: 0.25rem;
	padding: 0.4375rem 0.125rem;
	max-width: 100%;

	input {
		margin: 0;
		outline: none;
		border: 0;
		background: transparent;
		padding: 0;
		max-width: 100%;
		line-height: inherit;
		color: $white;
		font-size: inherit;
		appearance: none;

		&::placeholder {
			color: $medium-dark-gray;
		}
	}
}

@media screen and (min-width: 30em) {
	.react-tags__search {
		position: relative;
	}
}

.react-tags__suggestions {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	width: 100%;

	ul {
		margin: 0.25rem -1px;
		border: 1px solid $midnight-lighten-10;
		border-radius: 0.125rem;
		box-shadow: 0 0.125rem 0.375rem rgba($black, 0.2);
		background: $midnight-lighten-15;
		padding: 0;
		list-style: none;
	}

	li {
		border-bottom: 1px solid $midnight-lighten-10;
		padding: 0.375rem 0.5rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: $white;

		mark {
			background: none;
			text-decoration: underline;
			color: $white;
			font-weight: 600;
		}

		&:hover {
			background: $primary-color;
			cursor: pointer;
		}

		&.is-active {
			background: $primary-color;
		}

		&.is-disabled {
			opacity: 0.5;
			cursor: auto;
		}
	}
}

@media screen and (min-width: 30em) {
	.react-tags__suggestions {
		min-width: 15rem;
	}
}
