// sass-lint:disable force-pseudo-nesting, force-element-nesting, no-mergeable-selectors, no-transition-all

.switch {
	position: relative;
	margin-bottom: 1rem;
	outline: 0;
	padding-top: 0.5rem;
	height: 2rem;
	color: $white;
	font-size: 0.875rem;
	font-weight: bold;
	user-select: none;
}

.switch-input {
	position: absolute;
	opacity: 0;
	margin-bottom: 0;
}

.switch-paddle {
	display: block;
	position: relative;
	transition: all 0.25s ease-out;
	border-radius: 5000px;
	background: $medium-dark-gray;
	cursor: pointer;
	width: 3rem;
	height: 1rem;
	color: inherit;
	font-weight: inherit;
}

input + .switch-paddle {
	margin: 0;
}

.switch-paddle::after {
	display: block;
	position: absolute;
	top: -0.25rem;
	left: 0;
	transform: translate3d(0, 0, 0);
	transition: all 0.25s ease-out;
	border-radius: 5000px;
	box-shadow: 0 1px 1px rgba($black, 0.5);
	background: $white;
	width: 1.5rem;
	height: 1.5rem;
	content: '';
}

input:checked ~ .switch-paddle {
	background: darken($primary-color, 25%);
}

input:checked ~ .switch-paddle::after {
	left: 1.5rem;
	background: $primary-color;
}

input:focus ~ .switch-paddle {
	outline: 0;
}
