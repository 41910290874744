// sass-lint:disable no-mergeable-selectors, force-element-nesting, no-important

px-spinner,
.px-spinner {
	display: flex;
	align-items: center;
	flex-flow: row;
	justify-content: center;
	opacity: 0.75;
	height: 100%;

	&.small {
		transform: scale(0.32);
		margin-right: 1rem;
		height: 0.9rem;

		.preloader-wrapper {
			margin-right: -1rem;

			&.has-label {
				margin-right: $global-margin / 2;
			}
		}

		.spinner-layer {
			&.spinner-secondary {
				border-color: $white;
			}
		}

		.circle-clipper .circle {
			border-width: 4px;
		}
	}

	&.medium {
		transform: scale(0.64);
	}
}

.preloader-wrapper {
	display: inline-block;
	position: relative;
	width: 50px;
	height: 50px;
}

.preloader-wrapper.active {
	animation: container-rotate 1568ms linear infinite;
}

@keyframes container-rotate {
	to {
		transform: rotate(360deg);
	}
}

.spinner-layer {
	position: absolute;
	opacity: 0;
	border-color: $primary-color;
	width: 100%;
	height: 100%;

	&.spinner-white {
		border-color: $white;
	}

	&.spinner-black {
		border-color: $black;
	}

	&.spinner-secondary {
		border-color: darken($white, 33%);
	}
}

.active .spinner-layer {
	opacity: 1;
	animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes fill-unfill-rotate {
	12.5% {
		transform: rotate(135deg);
	}

	25% {
		transform: rotate(270deg);
	}

	37.5% {
		transform: rotate(405deg);
	}

	50% {
		transform: rotate(540deg);
	}

	62.5% {
		transform: rotate(675deg);
	}

	75% {
		transform: rotate(810deg);
	}

	87.5% {
		transform: rotate(945deg);
	}

	to {
		transform: rotate(1080deg);
	}
}

.gap-patch {
	position: absolute;
	top: 0;
	left: 45%;
	border-color: inherit;
	width: 10%;
	height: 100%;
	overflow: hidden;
}

.gap-patch .circle {
	left: -450%;
	width: 1000%;
}

.circle-clipper {
	display: inline-block;
	position: relative;
	border-color: inherit;
	width: 50%;
	height: 100%;
	overflow: hidden;
}

.circle-clipper .circle {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	border-width: 2px;
	border-style: solid;
	border-radius: 50%;
	border-color: inherit;
	border-bottom-color: transparent !important;
	width: 200%;
	height: 100%;
	animation: none;
}

.circle-clipper.left .circle {
	left: 0;
	transform: rotate(129deg);
	border-right-color: transparent !important;
}

.circle-clipper.right .circle {
	left: -100%;
	transform: rotate(-129deg);
	border-left-color: transparent !important;
}

.active .circle-clipper.left .circle {
	animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .circle-clipper.right .circle {
	animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes left-spin {
	from {
		transform: rotate(130deg);
	}

	50% {
		transform: rotate(-5deg);
	}

	to {
		transform: rotate(130deg);
	}
}

@keyframes right-spin {
	from {
		transform: rotate(-130deg);
	}

	50% {
		transform: rotate(5deg);
	}

	to {
		transform: rotate(-130deg);
	}
}

#spinnerContainer.cooldown {
	animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
