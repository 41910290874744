.px-date-picker-wrapper {
	// sass-lint:disable-block class-name-format, force-pseudo-nesting
	tbody {
		background-color: initial;

		tr {
			background: transparent;
		}
	}

	.CalendarDay__default {
		border: 1px solid transparent;
		background: transparent;
		color: rgba($white, 0.5);

		&:hover {
			border: 1px solid transparent;
			background: rgba($white, 0.1);
			color: inherit;
		}
	}

	.CalendarDay__selected_span {
		border: 1px double $primary-color;
		background: $primary-color;
		color: $white;
	}

	.CalendarDay__selected_span:active,
	.CalendarDay__selected_span:hover {
		border: 1px double $primary-color;
		background: lighten($primary-color, 15%);
		color: $white;
	}

	.CalendarDay__selected,
	.CalendarDay__selected:active,
	.CalendarDay__selected:hover {
		border: 1px double $primary-color;
		background: lighten($primary-color, 15%);
		color: $white;
	}

	.CalendarDay__blocked_calendar,
	.CalendarDay__blocked_calendar:active,
	.CalendarDay__blocked_calendar:hover {
		border: 1px solid darken($primary-color, 15%);
		background: darken($primary-color, 15%);
		color: $white;
	}

	.CalendarDay__blocked_out_of_range,
	.CalendarDay__blocked_out_of_range:active,
	.CalendarDay__blocked_out_of_range:hover {
		border: 1px solid transparent;
		background: transparent;
		color: rgba($white, 0.15);
	}

	.CalendarDay__today {
		font-weight: 700;
	}

	.CalendarMonth {
		background: transparent;
	}

	.CalendarMonth_caption {
		text-transform: uppercase;
		color: rgba($white, 0.75);
	}

	.CalendarMonthGrid {
		background: transparent;
	}

	.DayPickerNavigation_button__default {
		border: 1px solid transparent;
		background-color: initial;
	}

	.DayPickerNavigation_button__default:focus,
	.DayPickerNavigation_button__default:hover {
		border: 1px solid transparent;
	}

	.DayPickerNavigation_button__default:active {
		background: transparent;
	}

	.DayPickerNavigation_svg__horizontal {
		fill: $white;
	}

	.DayPicker__horizontal {
		background: transparent;
	}
}
