$px-asset-notes-controls-height: 2.5rem;

.px-asset-notes-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;

	.px-asset-notes {
		display: grid;
		position: absolute;
		top: 0;
		left: 0;
		grid-template: 1fr $px-asset-notes-controls-height / auto;
		grid-gap: 1.5rem;
		transform: scale(0);
		transform-origin: bottom right;
		transition: transform 0.3s cubic-bezier(1, 0, 0, 1), opacity 0.25s cubic-bezier(1, 0, 0, 1);
		opacity: 0;
		z-index: 0;
		background-color: rgba($black, 0.85);
		padding: 1.5rem;
		width: 100%;
		height: 100%;
		pointer-events: none;

		&.visible {
			transform: scale(1);
			opacity: 1;
			pointer-events: all;
		}
	}

	.px-asset-notes-body {
		border-bottom: 0;
		overflow-y: auto;

		& > div {
			position: relative;
			height: 100%;
		}
	}

	.px-asset-notes-footer {
		display: flex;
		align-content: center;
		flex-flow: row-reverse nowrap;
		justify-content: space-between;

		.button {
			margin-bottom: 0;
		}

		&.editing {
			.px-asset-notes-edit-hide {
				display: none;
			}

			.px-asset-notes-edit-show {
				display: block;
			}
		}

		&:not(.editing) {
			.px-asset-notes-edit-hide {
				display: block;
			}

			.px-asset-notes-edit-show {
				display: none;
			}
		}
	}

	p {
		display: inline-block;
		white-space: pre-wrap;
		color: $medium-gray;
	}

	textarea {
		display: block;
		margin-bottom: 0;
		width: 100%;
		height: inherit;
		line-height: 1.6;
		font-size: 1rem;

		&[disabled] {
			background-color: $dark-background;
			color: rgba($white, 0.5);
		}
	}

	.px-spinner {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.px-asset-notes-edit {
		position: relative;
	}

	.px-asset-notes-controls {
		.button {
			margin: 0;

			&:not(:last-child) {
				margin-right: 0.5rem;
			}
		}
	}

	.px-asset-notes-close-btn {
		background-color: $dark-gray;

		&:hover {
			background-color: $button-background-hover;
		}
	}
}
