$px-checkbox-disabled-label-color: #b8b8b8;
$px-checkbox-disabled-box-bg: #ddd;

.px-checkbox {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it

	& + label {
		position: relative;
		margin: 0;
		cursor: pointer;
		padding: 0;
		line-height: inherit;

		// Box
		&::before {
			display: inline-block;
			margin-right: 4px;
			border-radius: 2px;
			background: rgba($black, 0.5);
			width: 20px;
			height: 20px;
			vertical-align: text-top;
			content: '';
		}

		// Checkmark
		&::after {
			// sass-lint:disable-block indentation
			position: absolute;
			top: 12px;
			left: 5px;
			transform: rotate(45deg);
			opacity: 0.125;
			box-shadow:
				2px 0 0 $white,
				4px 0 0 $white,
				4px -2px 0 $white,
				4px -4px 0 $white,
				4px -6px 0 $white,
				4px -8px 0 $white;
			background: $white;
			width: 2px;
			height: 2px;
			content: '';
		}
	}

	// Box hover & focus
	&:hover,
	&:focus {
		& + label {
			&::before {
				box-shadow: 0 0 0 3px rgba($white, 0.05);
			}
		}
	}

	// Box checked
	&:checked {
		& + label {
			&::before {
				background: $primary-color;
			}

			&::after {
				opacity: 1;
			}
		}
	}

	// Disabled state label
	&:disabled {
		& + label {
			cursor: auto;
			color: $px-checkbox-disabled-label-color;

			// Disabled box
			&::before {
				box-shadow: none;
				background: $px-checkbox-disabled-box-bg;
			}
		}
	}
}
