.menu {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		position: relative;
		outline: 0;

		& > a {
			color: $white;
		}

		&.active {
			& > a {
				background: $primary-color;
			}
		}
	}

	a,
	.button {
		display: block;
		padding: 0.7rem 1rem;
		text-decoration: none;
		line-height: 1;
	}

	input,
	select,
	a,
	button {
		margin-bottom: 0;
	}

	input {
		display: inline-block;
	}

	&.vertical {
		flex-direction: column;
		flex-wrap: nowrap;
	}
}
