$primary-color: $blue-base;
$success-color: $green-base;
$warning-color: $yellow-base;
$alert-color: $red-base;

$medium-light-gray: $silver-lighten-20;
$medium-gray: $silver-base;
$medium-dark-gray: $silver-darken-15;
$dark-gray: $midnight-lighten-15;
$dark-background: $midnight-lighten-5;
$darkest-gray: $midnight-base;
$text-light: $medium-light-gray;
$body-background: $midnight-base;
$body-font-color: $white;

$body-text: $silver-darken-5;

$dashboard-item-bg: $midnight-lighten-5;

$color-primary-light: $blue-base;
$color-primary-dark: $midnight-darken-5;
$color-error: $red-base;
$color-error-dark: darken($red-base, 40%); // If we keep dark label bgs, make this a theme variable
$color-warning: $yellow-base;
$color-success: $green-base;
$color-info: $color-primary-light;
$color-disabled: $silver-darken-15;

$panel-light: $midnight-lighten-15;
$panel-dark: $midnight-lighten-10;

// asset cards
$px-card-bg: $midnight-lighten-5;
$px-card-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.25);
$px-card-hover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.33);

$px-asset-card-gradient: lighten($midnight-lighten-5, 2%);
