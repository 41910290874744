.px-asset-actions {
	flex-flow: row nowrap;

	li {
		&:not(:last-child) {
			margin-right: 1rem;
		}
	}

	.button {
		display: flex;

		&.expand-on-hover {
			.button-icon-label-text {
				transition: max-width 0.4s;
				margin-left: 0;
				padding: 0;
				max-width: 0;
				overflow: hidden;
			}

			.preloader-wrapper {
				margin-right: -3rem;
			}

			.button-icon-label-text-wrapper {
				margin-left: 0.5rem;
			}

			&.button-icon-label {
				svg {
					margin-right: 0;
				}
			}

			&:hover {
				.button-icon-label-text {
					max-width: 6.5em;
				}
			}
		}
	}
}
