$blue-base: #007bff;
$blue-lighten-5: #1a88ff;
$blue-darken-5: #006fe6;
$blue-lighten-10: #3395ff;
$blue-darken-10: #0062cc;
$blue-lighten-15: #4da3ff;
$blue-darken-15: #0056b3;
$blue-lighten-20: #66b0ff;
$blue-darken-20: #004a99;

$green-base: #48c289;
$green-lighten-5: #5bc895;
$green-darken-5: #3cb47c;
$green-lighten-10: #6ecfa2;
$green-darken-10: #36a16f;
$green-lighten-15: #81d5ae;
$green-darken-15: #2f8e62;
$green-lighten-20: #94dbba;
$green-darken-20: #297b55;

$yellow-base: #ffae00;
$yellow-lighten-5: #ffb61a;
$yellow-darken-5: #e69d00;
$yellow-lighten-10: #ffbe33;
$yellow-darken-10: #cc8b00;
$yellow-lighten-15: #ffc64d;
$yellow-darken-15: #b37a00;
$yellow-lighten-20: #ffce66;
$yellow-darken-20: #996800;

$red-base: #ed553b;
$red-lighten-5: #ef6952;
$red-darken-5: #eb4124;
$red-lighten-10: #f17e6a;
$red-darken-10: #e03215;
$red-lighten-15: #f39281;
$red-darken-15: #c92d12;
$red-lighten-20: #f6a698;
$red-darken-20: #b22810;

$midnight-base: #0d121a;
$midnight-lighten-5: #161e2b;
$midnight-darken-5: #050609;
$midnight-lighten-10: #1e2a3c;
$midnight-lighten-15: #27354d;
$midnight-lighten-20: #2f415e;

$silver-base: #9c9d9e;
$silver-lighten-5: #a9aaab;
$silver-darken-5: #8f9091;
$silver-lighten-10: #b6b7b7;
$silver-darken-10: #828485;
$silver-lighten-15: #c3c3c4;
$silver-darken-15: #767778;
$silver-lighten-20: #d0d0d0;
$silver-darken-20: #696a6b;

$white: #fefefe;
$black: #000000;

$blue-active-input: #265bad;
$blue: #265bad;
$base-font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
