.px-project-card {
	border-radius: $global-radius;
	box-shadow: $px-card-box-shadow;
	background: $px-asset-card-gradient;
	padding-bottom: 1rem;

	.px-project {
		.px-project-poster {
			@include aspect-ratio(4, 6);
			position: relative;
			margin-bottom: 1rem;
			border-radius: $global-radius $global-radius 0 0;
			background-color: rgba($white, 0.05);
			background-position: center center;
			background-size: cover;
			width: 100%;

			&.loading {
				&::before {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					background-color: rgba($white, 0.1);
					width: 100%;
					height: 100%;
					content: '';
					mask: url('/3x/img/svg/icon_movie.svg') no-repeat center;
					mask-size: 50%;
				}
			}
		}

		.button {
			position: absolute;
			right: 0;
			bottom: 0;
			margin: 0.75rem;
			background-color: $button-background;
			padding: 0;

			svg {
				margin: 0.5rem;
			}
		}

		& > span {
			// sass-lint:disable-block no-duplicate-properties, no-vendor-prefixes
			display: block;
			display: -webkit-box;
			padding: 0 1rem;
			overflow: hidden;
			text-decoration: none;
			font-size: 0.8rem;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}

		.px-project-name {
			color: $white;
			font-weight: bold;
		}
	}

	@include breakpoint(640) {
		.px-project {
			& > span {
				font-size: 0.9rem;
			}
		}
	}
}

.px-edit-project-button {
	&[tooltip] {
		&::before,
		&::after {
			transition-delay: 0.1s;
		}
	}
}
